import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';

class NavMenu extends Component {

    constructor(){
        super();
        this.state = {
            loggedIn: true,
        };
    }

    logout(){
        sessionStorage.removeItem("user");
        this.setState({"loggedIn":false});

    }
    
    render() {

        if (!this.state.loggedIn){
            return (<Redirect to={'/'}/>)
        }

        return (
            <div className="nav_menu_wrap">
                <Link to="/">
                    <i className="fa fa-home"></i>
                    <span>Home</span>
                </Link>
                <Link to="/tools">
                    <i className="fa fa-tools"></i>
                    <span>Tools</span>
                </Link>
                <Link to="/account">
                    <i className="fa fa-user"></i>
                    <span>Acct.</span>
                </Link>
                <Link to="/" onClick={()=>this.logout()}>
                    <i className="fa fa-lock"></i>
                    <span>Exit</span>
                </Link>
            </div>
        );
    }
}
export default NavMenu;