import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {PostData} from '../apis/fbsapi';

import Header from './Header';
import LightboxMessage from './LightboxMessage';
import LoadingSpinner from './LoadingSpinner';
// import Sidebar from './Sidebar';

class Dashboard extends Component {

    constructor(){
        super();
        this.state = {
            properties:'',
            error_type:'',
            error_message:'',
            show_loading:true,
            loading_message:'Getting your listings...',
        };
        this.getListings = this.getListings.bind(this);
    }

    componentDidMount() {
        // window.addEventListener('load', this.getListings);
        this.getListings();
    }

    getListings(){

        this.setState({
            show_loading:true,
            loading_message:"Getting your listings..."
        });
        
        PostData('get_user_listings',{user:sessionStorage.getItem('user')}).then((result) => {
            // console.log(result);
            if(result.type==="invalid_token"){
                sessionStorage.removeItem("user");
                window.location="/?login_required";
            }else if(result.type==="error"){
                
            }else{
                this.setState({
                    properties:result.properties,
                    show_loading:false,
                    loading_message:''
                })
            }

        });
    }

    renderProperties(){
        if(this.state.show_loading){
            return false;
        }else{
            if(this.state.properties){
                return this.state.properties.map((property, index) => {
                    // console.log(property);

                    // let background_image="background-image:url("+property.listing_image+")";

                    let featured_image="https://findbuyrent.com/api2/images/no_image.jpg";
                    if(property.images.length){
                        featured_image=property.images[0];
                    }

                    let background_image_styles = {
                        backgroundImage: `url(${featured_image})`
                    };

                    let listing_class="listing "+property.status;

                    return (
                      <div className={listing_class} key={index}>
                        {/* <div className="listing"></div> */}
                        <div className="listing_title">{property.address}</div>
                        <div className="listing_status">{property.status_formatted}</div>
                        <div className="clear"></div>
                        <div className="listing_image_dashboard" style={background_image_styles}></div>
                        <div className="listing_price"></div>
                        <div className="listing_info_row">
                            <div className="cell">
                                <div className="value">${property.price_formatted}</div>
                                <div className="label">PRICE</div>
                            </div>
                            <div className="cell">
                                <div className="value">{property.property_type_formatted}</div>
                                <div className="label">TYPE</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="listing_info_row">
                            <div className="cell">
                                <div className="value">{property.bedrooms}</div>
                                <div className="label">BEDROOMS</div>
                            </div>
                            <div className="cell">
                                <div className="value">{property.bathrooms}</div>
                                <div className="label">BATHROOMS</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="listing_controls">
                            <Link to={{ pathname: '/edit-listing', search: '?listing_id='+property.id }} className="button">
                                Manage This Listing
                            </Link>
                        </div>
                      </div>
                    );
                  });
            }else{
                return (
                    <div className="no_listings_message">
                        <p>You do not have any listings. Please click the button below to create a listing.</p>

                        <Link to="/new-listing" className="button">
                            <i className="fa fa-plus-circle"></i> Create New Listing
                        </Link>
                    </div>
                );
            }
        }
        
    }

    render() {
        return (
            <div className="dashboard_wrap">

            <LightboxMessage message={this.state.error_message} type={this.state.error_type} close_lightbox={this.hideLightboxMessage}></LightboxMessage>

            <LoadingSpinner show_loading={this.state.show_loading} loading_message={this.state.loading_message}></LoadingSpinner>

                <Header />
                {/* <Sidebar /> */}
                <div className="dashboard_content">
                    <h2 className="page_title"><i className="fa fa-list-ul"></i> Your Listings  
                        <Link to="/new-listing" className="button">
                            <i className="fa fa-plus-circle"></i> Create New Listing
                        </Link>
                    </h2>

                    { /* <div className="welcome_message">
                        Welcome, {JSON.parse(sessionStorage.getItem("user")).first_name}!
                    </div> */ }

                    <div className="listings">
                        {this.renderProperties()}
                    </div>

                </div>
            </div>
        );
    }
}
export default Dashboard;