import { USER_REGISTER } from '../actions/types';

export default (state={}, action) => {
    switch (action.type){
        case USER_REGISTER:
            // if(action.payload.type==="success"){
            //     console.log("logged in");
            // }else{
            //     console.log("fail");
            // }
            console.log(action.payload);
            return { ...state, "user_data":action.payload };
        default:
            return state;
    }
}