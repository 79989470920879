import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import registerReducer from './registerReducer';

// import { USER_REGISTER, USER_LOGIN } from '../actions/types';

// const songsReducer = () => {
//     return [
//         {title:"Song 1", duration:"4:05"},
//         {title:"Song 2", duration:"2:30"},
//         {title:"Song 3", duration:"3:54"},
//         {title:"Song 4", duration:"2:52"},
//     ];
// }

// const selectedSongReducer = (selectedSong=null, action) => {
//     if(action.type==='SONG_SELECTED'){
//         return action.payload;
//     }else{
//         return selectedSong;
//     }
// }

// const register_reducer = (state=[], action) => {
//     console.log(response);
//     return response;
// }

export default combineReducers({
    // register_reducer,
    form: formReducer,
    register: registerReducer
});