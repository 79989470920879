import React, {Component} from 'react';
// import {Redirect, Link} from 'react-router-dom';
// import {PostData} from '../apis/fbsapi';

import NavMenu from './NavMenu';

// import '../css/login.css';

import logo from '../images/logo.png';

class Header extends Component {
    
    render() {

        return (
            <div className="header_wrap">
                <div className="logo_wrap">
                    <img src={logo} alt="logo"/>
                    <h1>Find. Buy. Rent.</h1>
                </div>
                <NavMenu />
            </div>
        );
    }
}
export default Header;