import React, {Component} from 'react';

class LightboxMessage extends Component {
    
    render() {

        if(!this.props.message){
            return false;
        }

        let lightbox_class="lightbox_message "+this.props.type;

        let popup_icon=<div className="icon_wrap"><i className="fas fa-info-circle"></i></div>;

        if(this.props.type==="error"){
            popup_icon=<div className="icon_wrap"><i className="fas fa-exclamation-circle"></i></div>;
        }else if(this.props.type==="good"){
            popup_icon=<div className="icon_wrap"><i className="fas fa-check-circle"></i></div>;
        }

        return (
            <div className="lightbox_message_wrap">
                <div className="lightbox_message_mask"></div>
                <div className={lightbox_class}>
                    {popup_icon}
                    {this.props.message}
                    <div className="button_wrap">
                        <button className="button green close_lightbox" onClick={this.props.close_lightbox}>Close</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default LightboxMessage;