import React, {Component} from 'react';

class LoadingSpinner extends Component {
    
    render() {

        if(!this.props.show_loading){
            return false;
        }

        return (
            <div className="loading_spinner">
                <svg version="1.1" id="loader-1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50">
                    <path fill="#2e8d61" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" transform="rotate(40 25 25)"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path>
                </svg>
                <div className="loading_message">{this.props.loading_message}</div>
            </div>
        );
    }
}
export default LoadingSpinner;