import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
// import {PostData} from '../apis/fbsapi';

import Header from './Header';
// import Sidebar from './Sidebar';

class Tools extends Component {

    renderListingsWidgetCode(){
        let user_data=JSON.parse(sessionStorage.getItem("user"));

        return(
            '<div id="fbr_widget_container"></div>\n'+
            '<script>\n'+
            '     var fbr_widget_settings={\n'+
            '          "api_key":"'+user_data.api_key+'",\n'+
            '          "listing_status":"for_sale, sale_pending",\n'+
            '          "num_listings":10,\n'+
            '          "include_styles":true\n'+
            '     }\n\n'+
            '     var script=document.createElement("script");\n'+
            '     script.src="https://findbuyrent.com/resources/widgets/listings.js";\n'+
            '     document.getElementById("fbr_widget_container").appendChild(script);\n'+
            '</script>'
        );
    }

    render() {

        

        return (
            <div className="dashboard_wrap">
                <Header />
                {/* <Sidebar /> */}
                <div className="dashboard_content">
                    <h2 className="page_title"><i className="fa fa-tools"></i> Tools</h2>
           
                    <hr/>

                    <h4>Listings Widget</h4>
                    <p className="instructions">Add the following code snippet to your website to display your listings. You may also change the parameters to customize your widget.</p>

                    <textarea className="widget_code" spellCheck="false" readOnly value={this.renderListingsWidgetCode()} ></textarea>

                </div>
            </div>
        );
    }
}
export default Tools;