import React,{Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {PostData} from '../apis/fbsapi';

import LightboxMessage from './LightboxMessage';
import LoadingSpinner from './LoadingSpinner';

import Header from './Header';
// import Sidebar from './Sidebar';

class Account extends Component {

    
    constructor(){
        super();
        
        let user_data=JSON.parse(sessionStorage.getItem("user"));

        this.state = {
            first_name:user_data.first_name,
            last_name:user_data.last_name,
            email:user_data.email,
            new_password:'',
            new_password_again:'',
            error_type:'',
            error_message:'',
            show_loading:false,
            loading_message:'',
        };
        this.update_account = this.update_account.bind(this);
        this.onChange = this.onChange.bind(this);
        this.hideLightboxMessage = this.hideLightboxMessage.bind(this);
    }

     update_account(e) {
        e.preventDefault();

            var post_data=this.state;
            post_data.user=sessionStorage.getItem('user');

            this.setState({
                show_loading:true,
                loading_message:"Updating your account..."
            });
            
            PostData('update_user',post_data).then((result) => {
                console.log(result.user);
                if(result.user){
                    sessionStorage.setItem('user',JSON.stringify(result.user));
                    this.setState({
                        error_type:"good",
                        error_message:"Account updated.",
                        show_loading:false,
                        loading_message:""
                    });
                }else{
                    this.setState({
                        error_type:result.type,
                        error_message:result.message,
                        show_loading:false
                    });
                }

            });
    }
 
    hideLightboxMessage(){
        this.setState({
            error_type:'',
            error_message:'',
        })
    }
 
     renderError({error, touched}){
         if(touched && error){
             return(
                 <div className="form_error">
                     {error}
                 </div>
             );
         }
     }

     onChange(e){
         console.log(e);
        this.setState({[e.target.name]:e.target.value});
    }

    render() {
        return (
            <div className="dashboard_wrap">
                <LightboxMessage message={this.state.error_message} type={this.state.error_type} close_lightbox={this.hideLightboxMessage}></LightboxMessage>

                <LoadingSpinner show_loading={this.state.show_loading} loading_message={this.state.loading_message}></LoadingSpinner>

                <Header />

                <div className="dashboard_content">
                    <h2 className="page_title"><i className="fa fa-user"></i> Account</h2>

                    <p className="instructions">Use the form below to update your accounts and payment information.</p>

                    <form className="account_form fbs_form" onSubmit={this.update_account}>

                        <div className="element_wrap one_third row_first">
                            <label>First Name</label>
                            <input type="text" name="first_name" onChange={this.onChange} placeholder="First Name" value={this.state.first_name}/>
                        </div>

                        <div className="element_wrap one_third">
                            <label>Last Name</label>
                            <input type="text" name="last_name" onChange={this.onChange} placeholder="Last Name" value={this.state.last_name}/>
                        </div>

                        <div className="element_wrap one_third">
                            <label>Email</label>
                            <input type="text" name="email" onChange={this.onChange} placeholder="Email" value={this.state.email}/>
                        </div>

                        <div className="element_wrap half row_first">
                            <label>New Password (optional)</label>
                            <input type="password" name="new_password" onChange={this.onChange} placeholder="Password"/>
                        </div>

                        <div className="element_wrap half">
                            <label>Re-enter New Password</label>
                            <input type="password" name="new_password_again" onChange={this.onChange} placeholder="Password"/>
                        </div>

                        <button type="submit" className="login_submit">Register</button>
                    </form>

                </div>
            </div>
        );
    }
}
export default Account;