import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {PostData} from '../apis/fbsapi';

import LightboxMessage from './LightboxMessage';
import LoadingSpinner from './LoadingSpinner';

import logo from '../images/logo.png';

class Login extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            redirectToReferrer: false,
            show_loading:false,
            loading_message:"",
            error_message:"",
            error_type:"",

        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.hideLightboxMessage = this.hideLightboxMessage.bind(this);
    }


    login(e) {
        e.preventDefault();
        if(this.state.email && this.state.password){

            this.setState({
                show_loading:true,
                loading_message:"Validating login..."
            });

            PostData('login',this.state).then((result) => {
                if(result.user){
                    sessionStorage.setItem('user',JSON.stringify(result.user));
                    this.setState({
                        redirectToReferrer: true,
                        show_loading:false,
                        loading_message:""
                    });
                }else{
                    this.setState({
                        error_type:result.type,
                        error_message:result.message,
                        show_loading:false
                    });
                }
            });
        }else{
            this.setState({
                error_type:"error",
                error_message:"Please enter your email address and password.",
                show_loading:false
            });
        }
    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    hideLightboxMessage(){
        this.setState({
            error_type:'',
            error_message:'',
        })
    }
    
    render() {

        if (this.state.redirectToReferrer || sessionStorage.getItem('user')){
            return (<Redirect to={'/dashboard'}/>)
        }

        return (
            <div className="hp_bg">

                <LightboxMessage message={this.state.error_message} type={this.state.error_type} close_lightbox={this.hideLightboxMessage}></LightboxMessage>

                <LoadingSpinner show_loading={this.state.show_loading} loading_message={this.state.loading_message}></LoadingSpinner>

                <div className="hp_wrap">
                    <img src={logo} alt="logo"/>
                    <h1>Find. Buy. Rent.</h1>
                    <form className="login_form" onSubmit={this.login}>

                        <div className="form_inputs_wrap">

                            <div className="login_tabs_wrap">
                                <div className="tab active">
                                    Login
                                </div>
                                <Link to="/register" className="tab">
                                    Register
                                </Link>
                                <div className="clear"></div>
                            </div>

                            <div className="input_wrap">
                                <i className="fas fa-envelope"></i>
                                <input type="text" name="email" onChange={this.onChange} placeholder="Email"/>
                            </div>

                            <div className="input_wrap">
                                <i className="fas fa-lock"></i>
                                <input type="password" name="password" onChange={this.onChange} placeholder="Password"/>
                            </div>

                        </div>

                        <button type="submit" className="login_submit" onClick={this.login}>Login</button>
                    </form>
                </div>
            </div>
        );
    }
}
export default Login;