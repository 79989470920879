import React from 'react';
import { BrowserRouter , Route } from 'react-router-dom';

import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import Dashboard from './Dashboard';
import NewListing from './NewListing';
import EditListing from './EditListing';
import Tools from './Tools';
import Account from './Account';

const App = () => {
    return (
        <BrowserRouter >
            <Route path="/" exact component={LoginForm}/>
            <Route path="/register" component={RegistrationForm}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/new-listing" component={NewListing}/>
            <Route path="/edit-listing" component={EditListing}/>
            <Route path="/tools" component={Tools}/>
            <Route path="/account" component={Account}/>
        </BrowserRouter >
    );
}

export default App;