import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

// import {Redirect, Link} from 'react-router-dom';
import {PostData} from '../apis/fbsapi';

import Header from './Header';
import LightboxMessage from './LightboxMessage';
import LoadingSpinner from './LoadingSpinner';
// import Sidebar from './Sidebar';

// var geocoder, map, google;

const AnyReactComponent = ({ text }) => <div className='map_pointer'><i className="fas fa-map-marker-alt"></i></div>;

const SortableItem = SortableElement(({index,image,deleteFunction}) => {
    let background_image_styles = {
        backgroundImage: `url(${image})`
    };
    return (
        <div className="listing_image" style={background_image_styles} draggable>
            <div className="delete_image" onClick={()=>deleteFunction(index)}>
                <i className="far fa-times-circle"></i>
            </div>
        </div>
    );
});

const SortableList = SortableContainer(({items,deleteFunction}) => {
  return (
    <div className="sort_container">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} image={value} deleteFunction={deleteFunction}/>
      ))}
    </div>
  );
});

let params = new URLSearchParams(window.location.search);

class EditListing extends Component {
    constructor(){
        super();
        this.state = {
            listing_id:params.get("listing_id"),
            address:'',
            latitude:'',
            longitude:'',
            address_input:'',
            geo_address: '',
            images:[],
            price:'',
            home_type:'',
            parcel_id:'',
            bedrooms:'',
            bathrooms:'',
            sq_ft:'',
            lot_size:'',
            year_built:'',
            description:'',
            status:'',
            error_type:'',
            error_message:'',
            show_loading:true,
            loading_message:'',
        };
        this.renderMap = this.renderMap.bind(this);
        this.submit_property = this.submit_property.bind(this);
        this.onChange = this.onChange.bind(this);
        this.hideLightboxMessage = this.hideLightboxMessage.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {
        params = new URLSearchParams(window.location.search);

        this.setState({
            listing_id:params.get("listing_id")
        });
        this.getListingDetails();
        // params.delete('listing_id');
    }


    getListingDetails(){
        this.setState({
            show_loading:true,
            loading_message:"Getting your listings..."
        });
        
        PostData('get_listing_details',{user:sessionStorage.getItem('user'),listing_id:params.get("listing_id")}).then((result) => {
            // console.log(result);
            if(result.type==="invalid_token"){
                sessionStorage.removeItem("user");
                window.location="/?login_required";
            }else if(result.type==="error"){
                alert(result.message);
            }else{
                // console.log(result.listing_details);
                // console.log(result.listing_details.images);
                this.setState({
                    address:result.listing_details.address,
                    city:result.listing_details.city,
                    state:result.listing_details.state,
                    zip:result.listing_details.zip,
                    latitude:result.listing_details.latitude,
                    longitude:result.listing_details.longitude,
                    address_search:`${result.listing_details.address} ${result.listing_details.city}, ${result.listing_details.state} ${result.listing_details.zip}`,
                    geo_address: '',
                    images:result.listing_details.images,
                    price:result.listing_details.price,
                    home_type:result.listing_details.property_type,
                    parcel_id:result.listing_details.parcel_id,
                    bedrooms:result.listing_details.bedrooms,
                    bathrooms:result.listing_details.bathrooms,
                    sq_ft:result.listing_details.square_footage,
                    lot_size:result.listing_details.lot_size,
                    year_built:result.listing_details.year_built,
                    description:result.listing_details.description,
                    status:result.listing_details.status,
                    show_loading:false,
                    loading_message:''
                })
            }

        });
    }

    handleChange = address_search => {
        // console.log("change", address_search);
        this.setState({ address_search });
        // this.geocodeAddress(address_input);
    };

    handleSelect = address_search => {
        // console.log("change2");
        this.setState({ 
            // address, 
            address_search
        });
        this.geocodeAddress(address_search);
    }

    handleAddressButtonClick = e => {
        // console.log("change3");
        e.preventDefault();
        // this.setState({
        //     address_search: this.state.address_search
        // });
        this.geocodeAddress(this.state.address_search);
    }

    geocodeAddress(address_search) {
        // console.log("geocode",address_search);
        this.setState({
            geo_address: '',
        }, () => {
            geocodeByAddress(address_search)
            .then(results => this.setState({
                geo_address:results[0],
            }) )
            .catch(error => console.error('Error', error));
        });
    }

    renderMap(){
        if(this.state.geo_address){
            
            return(

                <div className="map_wrap">
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyB_q0EJQbRCuuh7Uk57nuzew2LdRA6FhLk" }}
                    center={{
                        lat: this.state.geo_address.geometry.location.lat(),
                        lng: this.state.geo_address.geometry.location.lng()
                      }}
                    defaultZoom={12}
                    
                    >
                    {/* <div lat={this.state.geo_address.geometry.location.lat()} lng={this.state.geo_address.geometry.location.lng()} text={'Kreyser Avrora'} ></div> */}

                        <AnyReactComponent
                            lat={this.state.geo_address.geometry.location.lat()}
                            lng={this.state.geo_address.geometry.location.lng()}
                            text={''}
                        />
                    </GoogleMapReact>
                </div>
            );

            // let mapSrc="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q="+encodeURIComponent(this.state.addressSearch)+"&amp;spn=0.369101,0.585022&amp;z=14&amp;iwloc=A&amp;output=embed";
           
        }else if(this.state.latitude && this.state.longitude){
            // console.log(this.state.latitude)
            // console.log(this.state.longitude)
            return(
                <div className="map_wrap">
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyB_q0EJQbRCuuh7Uk57nuzew2LdRA6FhLk" }}
                    center={{
                        lat: parseFloat(this.state.latitude),
                        lng: parseFloat(this.state.longitude)
                    }}
                    defaultZoom={12}
                    >
                        <AnyReactComponent
                            lat={parseFloat(this.state.latitude)}
                            lng={parseFloat(this.state.longitude)}
                            text={''}
                        />
                    </GoogleMapReact>
                </div>
            );
        }else{
            return;
        }
    }

    submit_property(e) {
        e.preventDefault();

            var post_data=this.state;
            post_data.user=sessionStorage.getItem('user');

            this.setState({
                show_loading:true,
                loading_message:"Saving your listing..."
            });
            
            PostData('edit_listing',post_data).then((result) => {
                let responseJson = result;
                if(responseJson.type==="invalid_token"){
                    sessionStorage.removeItem("user");
                    window.location="/?login_required";
                }else if(responseJson.type==="error"){
                    this.setState({
                        error_type:responseJson.type,
                        error_message:responseJson.message,
                        show_loading:false
                    });
                }else{
                    window.location="/dashboard?property_updated";
                    // console.log(result);
                }

            });
    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }

    hideLightboxMessage(){
        this.setState({
            error_type:'',
            error_message:'',
        })
    }

    fileUpload(e){
        let files = Array.from(e.target.files);
        if(files.length){
            files.forEach((file, i) => {

                let this_state=this;

                var reader = new FileReader();
                reader.onload=function(event){
                    this_state.setState({
                        images:this_state.state.images.concat(event.target.result)
                    });
                }
                reader.readAsDataURL(file);
            });
        }
    }

    renderImages=()=>{
        console.log(this.state.images);
        if(this.state.images.length){
            return (
                <div>
                    <span className="image_instructions">Drag and drop the images below to reorder them. The first image will be the featured image for the listing.</span>
                    <SortableList items={this.state.images} onSortEnd={this.onSortEnd} axis="xy" deleteFunction={this.deleteImage}/>
                </div>
            );
        }else{
            return (<span className="no_image_message">Click the button below to upload listing images.</span>);
        }
    }

    deleteImage=(index)=>{
        let new_array=this.state.images.slice(0, index).concat(this.state.images.slice(index + 1, this.state.images.length))
        this.setState({
            images:new_array
        });
    }

    onSortEnd=({oldIndex,newIndex})=>{
        this.setState({
            images: arrayMove(this.state.images, oldIndex, newIndex),
        });
    };

    render() {

        return (
            <div className="dashboard_wrap">

                <LightboxMessage message={this.state.error_message} type={this.state.error_type} close_lightbox={this.hideLightboxMessage}></LightboxMessage>

                <LoadingSpinner show_loading={this.state.show_loading} loading_message={this.state.loading_message}></LoadingSpinner>
                
                <Header />
                {/* <Sidebar /> */}
                <div className="dashboard_content">
                    
                    <h2 className="page_title"><i className="fa fa-plus-circle"></i> Manage Listing  
                        <Link to="/dashboard" className="button">
                            Cancel
                        </Link>
                    </h2>

                    {}

                    <form className="new_listing_form fbs_form" onSubmit={this.submit_property}>
                        {/* <input type="text" name="address" placeholder="Enter your listing address"/> */}

                        <PlacesAutocomplete
                            value={this.state.address_search ? this.state.address_search:""}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="address_search_wrap">
                                <input
                                {...getInputProps({
                                    placeholder: 'Enter your listing address',
                                    className: 'location-search-input',
                                })}
                                />
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>

                        <button className="address_search_button button green" onClick={this.handleAddressButtonClick}>
                            <i className="fa fa-search"></i>Search
                        </button>

                        {this.renderMap()}

                        <div className="form_section">

                            <h3><i className="fa fa-images"></i> Listing Images</h3>

                            <div className="images_holder">
                                {this.renderImages()}
                                <div className="clear"></div>
                            </div>

                            <label htmlFor="image_upload" className="button"><i className="fa fa-upload"></i> Upload Image</label>
                            <input type="file" name="images" onChange={this.fileUpload} multiple id="image_upload"/>

                        </div>

                        <div className="form_section">
                            <h3><i className="fa fa-list-ul"></i> Property Information</h3>

                            <div className="element_wrap one_third row_first">
                                <label>Price</label>
                                <input type="number" placeholder="Price" name="price" onChange={this.onChange} value={this.state.price}/>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Home Type</label>
                                <select name="home_type" onChange={this.onChange} value={this.state.home_type}>
                                    <option value=""></option>
                                    <option value="single_family">Single Family</option>
                                    <option value="condo">Condo</option>
                                    <option value="townhouse">Townhouse</option>
                                    <option value="multi_family">Multi Family</option>
                                    <option value="apartment">Apartment</option>
                                    <option value="mobile_manufactured">Mobile/Manufactured</option>
                                    <option value="coop_unit">Coop Unit</option>
                                    <option value="vacant_land">Vacant Land</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Parcel ID</label>
                                <input type="text" placeholder="Parcel ID" name="parcel_id" onChange={this.onChange} value={this.state.parcel_id}/>
                            </div>
                            
                            <div className="element_wrap one_third row_first">
                                <label>Bedrooms</label>
                                <input type="number" placeholder="Number of Bedrooms" name="bedrooms" onChange={this.onChange} value={this.state.bedrooms}/>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Bathrooms</label>
                                <input type="number" placeholder="Number of Bathrooms" name="bathrooms" onChange={this.onChange} value={this.state.bathrooms}/>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Finished Square Feet</label>
                                <input type="number" placeholder="Square Footage" name="sq_ft" onChange={this.onChange} value={this.state.sq_ft}/>
                            </div>

                            <div className="element_wrap one_third row_first">
                                <label>Lot Size (sq. ft.)</label>
                                <input type="number" placeholder="Lot Size" name="lot_size" onChange={this.onChange} value={this.state.lot_size}/>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Year Built</label>
                                <input type="number" placeholder="Year Built" name="year_built" onChange={this.onChange} value={this.state.year_built}/>
                            </div>

                            <div className="element_wrap one_third">
                                <label>Status</label>
                                <select name="status" onChange={this.onChange} value={this.state.status}>
                                    <option value=""></option>
                                    <option value="for_sale">For Sale</option>
                                    <option value="sold">Sold</option>
                                    <option value="sale_pending">Sale Pending</option>
                                    <option value="coming_soon">Coming Soon</option>
                                </select>
                            </div>

                            <div className="element_wrap">
                                <label>Description</label>
                                <textarea name="description" className="description" onChange={this.onChange} value={this.state.description}></textarea>
                            </div>
                        
                        </div>

                        <button type="submit" className="button green"><i className="fa fa-check"></i> Save Listing</button>

                    </form>

                </div>
            </div>
        );
    }
}
export default EditListing;